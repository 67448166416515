<template>
  <div class="message-template" @click="$emit('close')">
    <Header />
    <div
      v-for="item in list" 
      :key="item.id"
      @click.stop="$emit('confirm', item)"
    >
      <Card 
        :name="item.plantName" 
        :total="item.total || 0" 
        :content="item.smsContent" 
      />
    </div>
    <InfiniteLoading 
      :distance="200" 
      spinner="waveDots"
      @infinite="getMessageTemplate" 
    >
      <div slot="no-more" class="infinite-loading-no-more">
        暂无更多
      </div>
    </InfiniteLoading>
  </div>
</template>

<script>
import Card from './components/card';
import Header from '@/components/header';
import InfiniteLoading from "vue-infinite-loading";
import { fetchMessageTemplate } from '@/api/message';
export default {
  props: {
    // 模板类型 1 作物 2 会议促销 3 病虫害预防
    smsType: {
      type: Number,
      default: 1,
    },
  },
  components: {
    Card,
    Header,
    InfiniteLoading,
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      list: []
    }
  },
  methods: {
    /**
     * 获取短信模板
     */
    async getMessageTemplate($state) {
      const res = await fetchMessageTemplate({
        smsType: this.smsType,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      });
      this.pageNum++;
      this.list = this.list.concat(res.data);
      if (res.page.hasNextPage) {
        $state.loaded();
      } else {
        $state.loaded();
        $state.complete();
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.message-template {
  min-height: 100vh;
  background-color: #FAFBFC;
}
</style>